.header{
    height: 100px;
    margin-bottom: 20px;
}
.navbar{
    position: absolute;
    background-color: #4484FF !important;    
    top: 0px;
    right: 0px;
    left: 0px;
    justify-content: flex-end;
    padding: 20px 40px;

    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.5); 

}
.profile{
    display: flex;
    flex-direction: column;
    margin-right: 40px;
    color: white;
}
.profile :nth-child(1){
    font-size: larger;
    font-weight: bold;
}
.profile :nth-child(2){
    font-size: small;
}
.profile:hover{
    cursor: default;
}

.logoutBtn{
    color: white !important;
    font-size: large;
    background-color: transparent;
    padding-block: 5px;
    padding-inline: 10px;
    border-radius: 10px;
    border: none;
}
.logoutBtn:hover{
    background-color: rgba(3, 3, 3, 0.5);
}

.top-notification {
    position: fixed;
    top: 10px; /* Adjust this value as needed */
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999; /* Make sure it stays on top of other elements */
    width: 80%; /* Adjust width as needed */
    max-width: 600px; /* Optional: Limit the width of the notification */
  }
  
  .top-notification .MuiAlert-root {
    text-align: center; /* Ensure the text is centered */
  }
  