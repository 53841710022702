/* .main {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
} */

.hr {
  font-family: "Montserrat", sans-serif;
  display: flex !important;
  flex-direction: row;
  /* width: 100vw; */
  overflow: hidden;
}

.content {
  flex: 1;
  /* padding: 20px; */
  min-width: 60% !important;
}

.addButton {
  display: flex;
  height: 180px;
  /* width: 220px; */
  margin-top: 30px;
  text-align: center;
  border: 2px dotted silver;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
}
.letter {
  margin-left: 4px; /* Adjust the value as needed */
}

.addButton > button {
  background-color: transparent;
  border: none;
  height: 100%;
  width: 100%;
  color: black;
}

.addEmployee > button:hover {
  background-color: rgb(41, 39, 39);
  color: black;
}

/* .employee-dialog-main {
  max-width: 60vw !important;
  max-width: 800px !important;
} */

.profile-card {
  width: 75%;
  margin: 20px auto;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.standard-input {
  /* width: 80px !important; */
  margin-left: 10px !important;
  margin-top: 10px !important;
}

.dialog-content {
  display: flex !important;
  flex-wrap: wrap !important;
  padding: 50px 80px !important;
}

.inputs-employee input
/* ,.MuiTextField-root  */
{
  font-size: larger;
}

.required {
  margin-left: 30px !important;
  color: red;
}

.leaveContainer,
.notificationContainer,
.timerContainer {
  border: 2px solid rgba(188, 188, 188, 0.471);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 10px 20px -4px,
    rgba(0, 0, 0, 0.3) 0px 6px 12px -6px !important;
  padding: 20px 30px;
  width: 90% !important;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 50px;
}
.leaveTable,
.timerTable {
  background-color: #ffffff;
  border-radius: 20px;
}
/* ////////////////////////////////////////////////////////////////////////// */
.myTableHeader {
  text-align: left;
  font-size: xx-large;
  font-weight: bold;
  letter-spacing: 6px;
  word-spacing: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid black;
}
.myTableHeadCells {
  font-weight: bolder !important;
  font-size: large !important;
}
.myTableHeadSpan {
  color: grey !important;
  font-size: larger !important;
}
