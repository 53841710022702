.form{
    display: flex;
    flex-direction: column;
    padding: 0px 5% 20px 0px;
}
.form .MuiFormLabel-root {
    font-size: larger;
    left: 3%;
    top: 11%;
  }

  .add-emp-btn {
    width: 50% !important;
    margin: 30px auto !important;
    color: #ffad3d !important;
    border: 1px solid #4484FF !important; 
    font-size: larger !important;
    background-color: #4484FF !important;
  }

  .add-emp-btn:hover {
    border-color:  #4484FF;

    background-color: #a1b3d500 !important;
  }