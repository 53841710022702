.requestedChangeContainer {
    margin: 20px;
}

.requestedChange {
    width: 95%;
    margin: auto;
}

.pending {
    padding: 10px;
    border-radius: 5px;
    background-color: #4484FF;
    color: white;
}

.accepted {
    border-radius: 5px;
    padding: 10px;
    background-color: rgb(149, 209, 59);
}