/* @font-face {
  font-family: "Segoe UI";
  src: url("/path-to-your-fonts/segoeui.woff2") format("woff2"),
    url("/path-to-your-fonts/segoeui.woff") format("woff"),
    url("/path-to-your-fonts/segoeui.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
} */

.dialogTable {
  margin-top: 50px;
  padding: 10px 10px 30px 10px;
}
.dialogHeading {
  color: #0057ff !important;
  font-weight: bold !important;
  padding-bottom: 8px !important;
  /* font-size: x-large !important; */
}
.dialogtext {
  padding-left: 25px;
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
  font-size: large !important; 
  font-weight: 500 !important;
}
.dialogTabletext {
  padding-left: 25px;
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
  font-size: large;
  font-weight: 500 !important;
}
.dialogtextReport {
  margin: 10px 20px 20px 20px;
  padding-block: 5px !important;
  min-height: 200px;
  font-size: large;
  font-weight: 500 !important;
  border: 1px solid rgba(188, 188, 188, 0.471) !important;
  border-radius: 10px;
  background-color: rgba(188, 188, 188, 0.25);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 10px 20px -4px,
    rgba(0, 0, 0, 0.3) 0px 6px 12px -6px !important;
}
