.heading{
  color: #FFAD3D;
  text-align: center;
  text-decoration:overline underline;
}

.leaves-container {
  width: 100%;
}

.requestLeaveFront {
  display: flex;
  gap: 30px 20%;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  width: 40%;
  padding: 15px;
  /* border: none !important; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 10px 20px -4px,
    rgba(0, 0, 0, 0.3) 0px 6px 12px -6px;
  animation-duration: 2s;
}
.cardHeader {
  font-size: x-large;
  display: flex;
  justify-content: space-between;
}
.cardBody {
  display: flex;
  flex-direction: column;
}

.leaves-header {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 20px auto;
  gap: 20px;
}

.leaves-header-heading {
  font-size: x-large;
  margin: 0 auto;
  margin-top: 30px;
}

.leaves-space {
  margin-left: 20px !important;
}

.input-from-to .MuiTextField-root , .leave-reason .MuiTextField-root {
  padding: 0px !important;
  margin: 0px !important;
}

.Request-leave-headings {
  font-weight: 600;
  font-size: 25px;
  margin-top: 20px;
}

