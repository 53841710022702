.search-container {
    background-color: white;
    background-color: #4484FF;
    height: 250px;
    width: 90%;
    margin: auto;
    border-radius: 10px;
    border-left: 8px solid #ffad3d;
    border-bottom: 5px solid #ffad3d;
  }
  
  .night{
    position: absolute; /* position: relative; */
    width: 50%;
    height: 30%;
    transform: rotateZ(40deg);
    overflow: hidden;
  }
  
  .star{
    position: absolute;
    left: 50%;
    top: 50%;
    height: 4px;
    z-index: 1000;
    background: linear-gradient(-45deg, #FFAD3D, rgba(255, 173, 61, 0)); /* Update to yellow */
    border-radius: 999px;
    filter: drop-shadow(0 0 6px #FFAD3D); /* Update to yellow shadow */
    animation: tail 3s ease-in-out infinite,
               falling 3s ease-in-out infinite;
  }
  
  @keyframes tail{
    0%{
      width: 0;
    }
    30%{
      width: 100px;
    }
    100%{
      width: 0;
    }
  }
  
  @keyframes falling{
    0%{
      transform: translateX(0);
    }
    100%{
      transform: translateX(300px);
    }
  }
  
  .star::before, .star::after{
    content: '';
    position: absolute;
    top: calc(50% - 2px);
    right: 0;
    height: 4px;
    background: linear-gradient(-45deg, rgba(255, 173, 61, 0), #FFAD3D, rgba(255, 173, 61, 0)); /* Update gradient to yellow */
    border-radius: 100%;
    transform: translateX(50%) rotateZ(45deg);
    animation: shining 3s ease-in-out infinite;
  }
  
  @keyframes shining{
    0%{
      width: 0;
    }
    50%{
      width: 30px;
    }
    100%{
      width: 0;
    }
  }
  
  .star::after{
    transform: translateX(50%) rotateZ(-45deg);
  }
  
  .star:nth-child(1){
    top: calc(50% - 100px);
    left: calc(50% - 250px);
    animation-delay: 1s;
  }
  
  .star:nth-child(1)::before, .star:nth-child(1)::after{
    animation-delay: 1s;
  }
  
  .star:nth-child(2){
    top: calc(50% - 50px);
    left: calc(50% - 200px);
    animation-delay: 1.2s;
  }
  
  .star:nth-child(2)::before, .star:nth-child(2)::after{
    animation-delay: 1.2s;
  }
  
  .star:nth-child(3){
    top: calc(50% - 0px);
    left: calc(50% - 150px);
    animation-delay: 1.4s;
  }
  
  .star:nth-child(3)::before, .star:nth-child(3)::after{
    animation-delay: 1.4s;
  }
  
  .star:nth-child(4){
    top: calc(50% - -50px);
    left: calc(50% - 200px);
    animation-delay: 1.6s;
  }
  
  .star:nth-child(4)::before, .star:nth-child(4)::after{
    animation-delay: 1.6s;
  }
  
  .star:nth-child(5){
    top: calc(50% - -100px);
    left: calc(50% - 250px);
    animation-delay: 1.8s;
  }
  
  .star:nth-child(5)::before, .star:nth-child(5)::after{
    animation-delay: 1.8s;
  }