/* .personalD {
    display: flex;
    flex-direction: column;
    gap: 10px;
} */


.picture-header {
    /* height: 130px; */
    /* background-color: red; */
    display: flex;
    flex-direction: row;
}

.main-header {
    height: 220px;
    background-color: #FFAD3D;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
    /* justify-content: space-between; */
}

.emp-name-pos {
    font-size: 20px;
    margin-top: auto;
    text-align: center;
}

.emp-name , .emp-pos {
    margin: 20px;
}

.toggle-head {
    display: flex;
}

.toggle-btn {
    border: none;
    padding: 10px 30px !important;
    margin: 0px !important;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.activeNav {
    background-color: #F1F0F0;
}

.deactivatedNav {
    background-color: #FFAD3D;
}
.deactivatedNav:hover {
    background-color: #4484FF;
    color: #F1F0F0;
}

