.salary-container {
    width: 95%;
    margin-left: 20px;
    margin-top: 20px;
    font-family: "Montserrat", sans-serif;
}

.heading-large {
    font-size: larger;
}

.heading-xx-large {
    font-size: xx-large;
    margin-bottom: 1.5rem;
}

.total-sal,
.total-tax {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 50px;
}

.input-like-sal-info {
    width: 100%;
    height: min-content;
    padding: 5px;
    border: none;
    outline: none;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: smaller;
}

.sal-input {
  display: flex;
}

.grid-container-salary , .grid-container-extra-increments {
    display: grid;
    grid-template-columns: auto auto;
    background-color: white;
    /* padding: 10px; */
    width: 100%;
    margin: 10px auto;
    border-radius: 15px;
  }

  
.grid-container-projects-increments , .grid-container-retainers-increments {
    display: grid;
    grid-template-columns: auto auto auto;
    background-color: white;
    /* padding: 10px; */
    width: 100%;
    margin: 10px auto;
    border-radius: 15px;
  }
  
  .grid-item-salary {
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(224, 220, 220, 0.8);
    padding: 10px 20px;
    font-size: 20px;
    text-align: center;
  }

  .add-sal-btn {
    
  }

  /* .grid-item-salary-head {
    font-weight: 100;
  } */
  