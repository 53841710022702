.cardContainerP {
  display: flex !important;
  gap: 30px 9% !important;
  flex-wrap: wrap !important;
  justify-content: center !important;
  text-align: center !important;
  padding-bottom: 50px;
}
.cardProfile {
  height: 320px !important;
  width: 330px !important;
  padding: 0px !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 10px 20px -4px,
    rgba(0, 0, 0, 0.3) 0px 6px 12px -6px !important;
  animation-duration: 2s !important;
}
.manager-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #ffad3e;
  font-size: xx-large;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: -3px 2px 4px rgba(255, 255, 255, 0.5);
}

.cardProfileHeader {
  background-color: #4484ff;
  height: 120px;
  max-height: 150px;
}
.avatarr {
  background-color: rgb(0, 0, 0) !important;
  position: fixed !important;
  top: 15% !important;
  left: 30% !important;
  font-size: 50px !important;
  width: 40% !important;
  height: 40% !important;
}
.cardProfileBody {
  padding-top: 65px;
}
.cardProfileBody h4 {
  font-weight: bolder;
  letter-spacing: 1px;
  word-spacing: 2px;
}
.cardProfileBody button {
  border: 2px solid #4484ff;
  color: #4484ff;
}
