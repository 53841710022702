body {
    font-family: "Montserrat", sans-serif;

}

.documents-container {
  margin: 20px;
}

.heading-xx-large {
    font-size: xx-large;
    margin-bottom: 1.5rem;
}

.docs-container {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-top: 20px;
    width: 95%;

}

.docs-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

input[type="file"] {
    position: relative;
  }
  
  input[type="file"]::file-selector-button {
    width: 136px;
    color: transparent;
  }
  
  /* Faked label styles and icon */
  input[type="file"]::before {
    position: absolute;
    pointer-events: none;
    top: 10px;
    left: 16px;
    height: 20px;
    width: 20px;
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%230964B0'%3E%3Cpath d='M18 15v3H6v-3H4v3c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-3h-2zM7 9l1.41 1.41L11 7.83V16h2V7.83l2.59 2.58L17 9l-5-5-5 5z'/%3E%3C/svg%3E");
  }
  
  input[type="file"]::after {
    position: absolute;
    pointer-events: none;
    top: 7px;
    left: 40px;
    color: #0964b0;
    content: "Choose File";
  }
  
  /* ------- From Step 1 ------- */
  
  /* file upload button */
  input[type="file"]::file-selector-button {
    border-radius: 4px;
    padding: 0 16px;
    height: 40px;
    cursor: pointer;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.16);
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    margin-right: 16px;
    transition: background-color 200ms;
  }
  
  /* file upload button hover state */
  input[type="file"]::file-selector-button:hover {
    background-color: #f3f4f6;
  }
  
  /* file upload button active state */
  input[type="file"]::file-selector-button:active {
    background-color: #e5e7eb;
  }

  .upload-button {
    color: transparent;
    color: #0964b0;
    border-radius: 4px;
    padding: 0 20px;
    height: 40px;
    cursor: pointer;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.16);
  }

  .upload-button:hover {
    background-color: #f3f4f6;

  }

  .all-documents {
    margin-top: 10px;
    width: 95%;
  }


  .doc-key , .all-doc-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
  }

  .dual-ball {
    background: none;
  }


  
  

