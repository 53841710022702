.proj_main {
  margin: 0px !important;
}

.proj_mainImage {
  text-align: center;
}
.proj_middle {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: auto;
}
.proj_middle :first-child {
  font-weight: bold;
}

.proj_bottom {
  display: flex;
  gap: 30px 10%;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  min-height: 500px;
}
.proj_card {
  height: 300px;
  display: flex;
  align-items: center;
  text-align: center;
  background-color: rgb(239, 239, 239);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 10px 20px -4px,
    rgba(0, 0, 0, 0.3) 0px 6px 12px -6px;

  animation-duration: 2s;
  transition: background-color 1s ease;
  cursor: pointer;
}
.proj_card:hover {
  background-color: rgb(198, 247, 247);
}
.proj_card img {
  max-width: 100%;
}

.projHeader{
  padding-bottom: 0px !important;
  border-bottom: none !important;
}

@import url('https://fonts.googleapis.com/css2?family=Exo:wght@400;700&display=swap');
.numbers{
  font-family: 'Exo', sans-serif;
  font-weight: bold;
  color: #ff9500;
  font-size: xx-large;
}