.sidebar-space {
  width: 280px;
  margin-right: 0px;
}
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 280px !important;
  height: auto;
  min-height: 100vh;
  background-color: rgb(255, 255, 255);
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-top: 20px; */
  border-top-right-radius: 100px;
  border-right: 1px solid rgba(128, 128, 128, 0.53);
  box-shadow: rgba(50, 50, 93, 0.55) 0px 13px 24px -4px,
    rgba(0, 0, 0, 0.5) 0px 8px 16px -6px;
  z-index: 1;
}

.side-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.buttons {
  display: flex;
  flex-direction: column;
}
.btn-53 .original {
  background: rgb(65, 90, 212) !important;
}
.btn-53.start {
  padding: 20px 10px;
}
.logo {
  margin: auto;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  font-weight: bolder;
  font-size: x-large;
}
.menu {
  width: 100%;
  list-style: none;
  padding: 0;
}
.menu-item {
  padding: 15px 20px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s;
}
.menu-item:hover {
  background-color: #575757;
}

.navButton {
  border: none;
  padding-block: 12px;
  border-left: 8px solid white;
  text-align: left !important;
  padding-left: 30px;
  font-size: large;
  font-weight: 500 !important;
  background-color: white;
}
.navButton:hover {
  border-left: 8px solid #4486ff9f;
  background-color: #dadada9f;
}
.navButton.activeNav {
  border-left: 8px solid #4484FF;
  background-color: #dadada;
}
