
.main {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}


.addButton {
    display: flex;
    height: 180px ;
    width: 220px ;
    margin-top: 30px;
    text-align: center;
    border: 2px dotted silver;
    border-radius: 10px;
    align-items: center;
    justify-content: center;


}

.addButton > button {
    
    background-color: transparent;
    border: none;
    height: 100%;
    width: 100%;
    color: black;
}

.addEmployee > button:hover {
    
    background-color: gainsboro;
    color: black;
    
}

.add-proj {
    display: inline !important;
}

.employee-dialog-main {
    /* max-width: 60vw !important; */
    /* max-width: 800px !important; */
}

.standard-input {
    /* width: 80px !important; */
    margin-left: 10px !important;
    margin-top: 10px !important;
}

.dialog-content {
    display: flex !important;
    flex-wrap: wrap !important;
    padding: 50px 80px !important;
}

.inputs-employee , .MuiTextField-root {
    margin: 10px 10px !important;
    flex: 1 1 45% !important; /* Flex-grow, flex-shrink, flex-basis */
    box-sizing: border-box !important; /* Ensures padding and border are included in width */
    padding: 10px !important;
}

.required {
    margin-left: 30px !important;
    color: red;
}

.evaluation-main  {
    display: flex;
    flex-direction: column;
    margin: 20px !important;
    background-color:#e8fffd;
    border-radius:20px;
}

.evaluation {
    margin-left:200px;
    margin-right:200px;
    margin-top:20px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.btns {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.emailSelect {
    width: 400px;
    margin: 20px auto;
}

.evaluation-table {
    margin: 30px 30px;
    /* background-color: #4f928d !important; */
}

.eval-table {
    background-color: #e8fffd;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    border-radius: 10px;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    width: -webkit-fill-available !important;
  }