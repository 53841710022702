.dashboardFront {
  display: flex;
  gap: 30px 10%;
  flex-wrap: wrap;
  justify-content: center;
}
.card {
  width: 40%;
  padding: 15px;
  /* border: none !important; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 10px 20px -4px,
    rgba(0, 0, 0, 0.3) 0px 6px 12px -6px;
  animation-duration: 2s;
}
.cardHeader {
  font-size: x-large;
  display: flex;
  justify-content: space-between;
}
.cardBody {
  display: flex;
  flex-direction: column;
}
.cardBody :nth-child(1) {
  font-size: 60px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 600;
}
.cardBody :nth-child(2) {
  font-size: larger;
  font-weight: light;
}
.dashboardBottom {
  padding: 50px 55px;
  font-size: larger;
}
.dashboardTimerTableContainer {
  border: 2px solid rgba(188, 188, 188, 0.471) !important;

  box-shadow: rgba(50, 50, 93, 0.25) 0px 10px 20px -4px,
    rgba(0, 0, 0, 0.3) 0px 6px 12px -6px !important;
  padding: 20px 30px;
}
.dashboardTableRow {
  height: 50px;
  font-size: 40px !important;
}
.active {
  background-color: rgb(53, 254, 53) !important;
  padding: 5px 10px;
  border-radius: 8px;
}
.onLeave {
  background-color: rgb(255, 39, 255) !important;
  padding: 5px 10px;
  border-radius: 8px;
}
.inActive {
  background-color: rgb(255, 68, 68) !important;
  padding: 5px 10px;
  border-radius: 8px;
}
