.handle-preloader .animation-preloader .txt-loading .letters-loading{
	color: #4484FF;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:before{
	color: #ffad3d;
}

.handle-preloader .animation-preloader .spinner{
	border: 3px solid #4484FF;
    border-top-color: rgba(255, 173, 61, 1); 
}