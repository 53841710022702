@import url('https://fonts.googleapis.com/css2?family=Exo:wght@400;700&display=swap');

.masterTop {
  height: 60px;
  display: flex;
  justify-content: space-between;
  padding-right: 5%;
}
.backButton {
  transition: background-color 2s ease !important;
}
.backButton:hover {
  background-color: rgb(198, 247, 247) !important;
}
.masterInput .MuiFormLabel-root {
  left: 3%;
  top: 11%;
}
.masterInput {
  width: 400px !important;
}
.masterHeader {
  margin: 20px 5% 10px 5%;
}
.masterMid {
  display: flex;
  margin: 0px 5%;
}
.masterImage {
  width: 40%;
  text-align: center;
}
.masterTable {
  width: 60% !important;
  padding: 10px 20px;
  margin-top: 20px;
  background-color: white;
  border: 2px solid rgba(188, 188, 188, 0.471);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 10px 20px -4px,
    rgba(0, 0, 0, 0.3) 0px 6px 12px -6px !important;
}

.masterSearch {
  width: 60% !important;
  margin: 20px 5%;
  border: 2px solid rgba(188, 188, 188, 0.471);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 10px 20px -4px,
    rgba(0, 0, 0, 0.3) 0px 6px 12px -6px !important;
}
.numbers{
  font-family: 'Exo', sans-serif;
  font-weight: bold;
  color: #0062ff;
  font-size: xx-large;
}