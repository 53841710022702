.personal-container {
    margin: 20px;
    /* height: 300px; */
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    font-family: "Montserrat", sans-serif;
}

.personal-info-row-1 , .personal-info-row-2 , .personal-info-row-3 , .personal-info-row-4  {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-right: 0px;
    margin-top: 20px;
}
/* .personal-info-row-2 {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-right: 0px;
    margin-top: 10px;
} */

.personal-info-row-1 .MuiTextField-root {
    margin: 0px 0px !important;
    padding: 0px !important;
    display: flex;
    width: 90%;
}

.Emp-label-input {
    width: 33%;
    /* margin: auto; */
}

.Emp-label-input p {
    margin-bottom: 5px;
    font-family: "Montserrat", sans-serif;
}

.input-like-info {
    width: 90%;
    padding: 5px;
    border: none;
    outline: none;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: smaller;
}

.react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    width: 100% !important;
}

.emp-input {
    display: flex;
}

.qualification {
    margin: 20px;
    height: 300px;
    /* background-color: red; */
}

.personal-head-heading {
    font-size: xx-large;
}

.basic-info-head {
    font-size: x-large;
}