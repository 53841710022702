


.dialogHeading {
  color: #0057ff;
  font-weight: bold !important;
  padding-bottom: 8px !important;
  /* font-size: x-large !important; */
}
.dialogtext {
  padding-left: 25px;
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
  font-size: large;
  font-weight: 500 !important;
}
.dialogtextReport {
  margin: 10px 20px 20px 20px;
  padding-block: 5px !important;
  min-height: 200px;
  font-size: large;
  font-weight: 500 !important;
  border: 1px solid rgba(188, 188, 188, 0.471) !important;
  border-radius: 10px;
  background-color: rgba(188, 188, 188, 0.25);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 10px 20px -4px,
    rgba(0, 0, 0, 0.3) 0px 6px 12px -6px !important;
}
.dialogTop {
  display: flex;
  width: 100%;
}
.dialogLeft {
  width: 50%;
}
.dialogRight {
  width: 50%;
  height:200px;
  /* background-color: rgb(0, 0, 0); */
  text-align: center;
  align-self:flex-end;
}
