.heading {
  color: #ffad3d;
  text-align: center;
  text-decoration: overline underline;
}
.eventsImage {
  border: 0px;
  text-align: center;
}
.birthdayContainer {
  width: 90%;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}
.birthdayInnerContainer {
  border: 2px solid rgba(188, 188, 188, 0.471) !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 10px 20px -4px,
    rgba(0, 0, 0, 0.3) 0px 6px 12px -6px !important;
  padding: 30px;
  border-radius: 10px !important;
}
.eventRow {
  width: 100%;
  /* margin-block-end: 20px; */
  /* background-color: #e1e1e1 !important; */
  /* border-radius: 20px !important; */
  /* display: flex;
  justify-content: space-between; */
  /* padding-inline: 20px; */
}

/* style={{ marginBlock: "30px", width:"100%" }} */
