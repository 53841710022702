body {
    background-color: #F1F0F0 !important;
}

h1 {
    margin-bottom: 0 !important;
}

.leave-days {
    margin: 10px !important;
    padding: 10px !important;
}

.time-btns {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
}

.employee {
    display: flex !important;
    flex-direction: row;
    /* width: 100vw; */
    overflow: hidden;
  }
.content {
    flex: 1;
    /* padding: 20px; */
    width: 60% !important;
}

.timer {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: xx-large;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.countdown-space {
    margin-left: 20px !important;
}

.countdown-timer {
    min-height: 120px !important;
    width: 100%;
    /* background-color: red; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.timer-blocks {
    padding: 30px 50px;
    min-height: 100px !important;
    width: 100%;
    text-align: center;
    align-content: center;
    border-radius: 10px;
    border: 2px solid #FFAD3E;
    background-color: #9ABDFF;
}
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@400;700&display=swap');

.timer-blocks p {
    font-size: 2rem;
    font-family: 'Rajdhani', sans-serif;

}

.hours , .minutes , .seconds {
    display: flex;
    flex-direction: column;
    /* width: 50%; */
    margin: 20px;
}



.hours p, .minutes p, .seconds p {
    text-align: center;
    margin-bottom: 0;
}

.colons {
    margin: 10px;
    margin-top: -20px; ;
}

.rectangles {
    width: 10px;
    height: 10px;
    background-color: #403c3c;
    margin-bottom: 10px;
}

.digit-separation {
    width: 1.5px;
    min-height: 100%;
    background-color: black; /* Vertical border color */
    margin: 0 0.2rem;
  }


/* ------------TOGGLE BUTTONS FOR TIMER-----------------  */

.toggles {
    width: 100%;
    height: 100px;
    display: flex;
    gap: 45px;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;
}

.toggle-btns {
    min-width: 15%;
    height: 60%;
    background-color: #9ABDFF;
    border: none;
    border-radius: 10px;
    font-size: 23px;
}
.toggle-btns:hover{
    border: 2px solid #FFAD3E;
}

.toggleImgs {
    margin: 10px;
    margin-bottom: 12px;
}

.main-report {
    width: 100%;
    /* height: 400px; */
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.project-type {
    width: 30%;
    /* background-color: blue; */
    margin: 20px;
}

.mastermind-submit {
    display: flex;
    gap: 25px;
}

.appointments , .emailsent , .pipelines {
    text-align: center;
    align-content: center;
    width: 300px;
    /* background-color: #9ABDFF; */
}

.progress-report-submit {
    width: 80%;
    margin: 20px;
}

.main-report  .MuiOutlinedInput-root {
    background-color: #FFFFFF !important;
}

.submit-btn {
    padding: 7px 40px;
    margin: 20px;
    background-color: #9ABDFF !important;
    border: none;
    border-radius: 10px;
    font-size: 23px;
}

#appointmentTaken-label {
    margin: inherit;
}

.heading{
    color: #FFAD3D;
    text-align: center;
    text-decoration:overline underline;
  }




